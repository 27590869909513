import React, { useEffect, useState } from 'react';
import { convertToModule } from 'factory/utils/clientUtils';
import styles from "./ReOrderWidget.module.scss";
import HK from 'factory/modules/HK';
import OwlCarousel from 'utility/templates/owlCarousel';
import SkeletonPattern from 'utility/templates/skeletons/SkeletonPattern';
import GritzoProductCard from 'components/VariantCards/GritzoProductCard';
import { __isMobile, __localStorageGet } from 'factory/modules/HK_service';

const ReOrderWidget = (props) => {
    const isMobile = props.isMobile || __isMobile();
    const [reOrderitem, setreOrderitem] = useState(null);
    const [showSkel, setshowSkel] = useState(false);
    useEffect(() => {
        callPageData();
    }, []);

    const user = __localStorageGet('userData');
    const GritzoCash = user.tot_redeem_pts;

    const callPageData = () => {
        let obj = {
            url: HK.config.APIS.myAccount.reOrderwidget,
            method: "get",
        };
        setshowSkel(true);
        HK.ajaxCall(obj).then((res) => {
            if (res && res.data && res.data.results && !res.data.results.exception) {
                setreOrderitem(res.data.results);
            }
        }).catch((e) => console.log(e.msg, "Error while fetching pageData : reorder-widget"))
            .finally(() => setshowSkel(false));
    };

    const sections = reOrderitem && reOrderitem.items && reOrderitem.items.length > 0;
    return (
        sections &&
        <div className={convertToModule(styles, "buyAgain")}>
            <div className={convertToModule(styles, "HK-pagecontainer")}>
                {/* {renderSvg()} */}
                {props.data && (props.data.dis_nm || props.data.nm) ?
                    <div className={convertToModule(styles, "heading")}>
                        {(props.data.dis_nm || props.data.nm)}
                    </div>
                    : <></>
                }
                {GritzoCash && GritzoCash > 0 && props.data && props.data.desc  ?
                    <div className={convertToModule(styles, "sub-heading")}>
                        { props.data.desc.replace("$", `₹${GritzoCash}`)}
                    </div> :
                    <></>
                }
                
                {
                    <div className={convertToModule(styles, "buyAgain-products")}>
                        {
                            showSkel ?
                                (
                                    isMobile ? <SkeletonPattern pattern={[8]} /> :
                                        <SkeletonPattern pattern={[13]} />
                                )
                                :
                                <OwlCarousel
                                    className={convertToModule(styles,"BS-Desk" )}
                                    margin={0}
                                    items={isMobile ? 2 : 3}
                                    slideBy={isMobile ? 1 : 2}
                                    dots={false}
                                    nav={isMobile ? false : true}
                                    responsive={false}
                                >
                                    {
                                        reOrderitem.items.map((item, index) => {
                                            if (!item) return;
                                            let url = item.urlFragment.replace('https://www.gritzo.com', '').replace('/pk', '').replace('/sv', '');
                                            url = item.pk_type ? '/pk' + item.urlFragment : '/sv' + item.urlFragment;
                                            url += `?navKey=${item.navKey}`;
                                            return <React.Fragment key={`reorderItem_${index}`}>
                                                <GritzoProductCard pageType={props.pageType} data={item} url={url} title={props.title} eventName={"Reorder"} widgetPosition={props.data.positionIdx} index={index} widgetType={"ReOrder"} btncustom={convertToModule(styles, "btn-custom")} />
                                            </React.Fragment>;
                                        })
                                    }
                                </OwlCarousel>
                        }

                    </div>
                }
            </div>
        </div>
    );
};

export default ReOrderWidget;