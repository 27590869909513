import React, { Component } from 'react';
import HK from 'factory/modules/HK';
import styles from "static/css/errorPage/errorPage.module.scss";
import { convertToModule } from 'factory/utils/clientUtils';
class FourOFourPage extends Component {
    componentDidMount() {
        let args = {
            title: 'Page Not Found | Gritzo',
            metaDesc: '',
            canonicalUnknown: true
        }
        HK._setSEO(args, null);
    }
    // setCanonicalUrl = () => {
    //     if (document.querySelector('link[rel="canonical"]')) {
    //         document.querySelector('link[rel="canonical"]').setAttribute('href', '')
    //     }
    // }
    render() {
        return (
            <div>
                <div className={convertToModule(styles, "error-pagecont")}>
                    <div className={convertToModule(styles, "img-error")}><img src={HK.getImage('/common/misc/image_404.jpg')} alt="image_404.jpg" /></div>
                    <div className={convertToModule(styles, "content-error")}>
                        <h3>Uh-Oh! Something here is broken.</h3>
                        <p>The page you're looking for is either removed from this location or the URL is wrong.
                            Let's take you to a better place.</p>
                        <a href="/">Go To Home Page</a>
                    </div>
                </div>
            </div>
        )
    }
}
export default FourOFourPage;