import React, { useEffect } from 'react';
import styles from './productCard.module.scss';
import { convertToModule, convertImageCF } from 'factory/utils/clientUtils';
import HK from 'factory/modules/HK';
import AddToCart from 'utility/templates/Generic/AddToCart';
import ReviewStarts from 'components/ReviewRevamppdp/ReviewStars/index.jsx';
import BuyNow from 'utility/templates/Generic/BuyNow';
import ProductPriceLoyalityTag from 'components/Loyality/ProductPriceLoyalityTag';
import { __isMobile, __loggedIn } from 'factory/modules/HK_service';
import { connect } from 'react-redux';
import { __getPLPItemPricing } from 'components/Loyality/Loyality_Service';

const GritzoProductCard = ({ data, url, title, eventName, widgetPosition, index, pageType, isWidth50 = false, _userRoleReducer, isPack,  placeholderConfig = {
    isConcern: false,
    isDiscount: false
}, widgetType,/*btncustom, isLoggedin, */ }) => {
    useEffect(() => {
        HK.chatIcon(false);
    }, []);
    const getAge = () => {
        let _age = "";
        if (data && data.grps && data.grps.length > 0) {
            data.grps.map(ele => {
                if (ele && (ele.nm === "gz-filter-pro" && ele.type === 1 && ele.dis_nm === "Gritzo Feature")) {
                    if (ele.values && ele.values.length > 0) {
                        ele.values.map(_data => {
                            if (_data && _data.nm === "gz-age") {
                                if (_data.val) {
                                    _age = _data.val.split(",")[0];
                                }
                            }
                        });
                    }
                }
            });
        }
        return _age;
    };

    const getFlavour = () => {
        let _flv = "";
        if (data && data.grps && data.grps.length > 0) {
            data.grps.map(ele => {
                if (ele.nm === "hfd-flavour-g" && ele.dis_nm === "Flavour") {
                    if (ele.values && ele.values.length > 0) {
                        ele.values.map(_data => {
                            if (_data.nm === "hfd-flavour") {
                                if (_data.val) {
                                    _flv = _data.val;
                                }
                            }
                        });
                    }
                }
            });
        }
        return _flv;
    };

    const getMRP = () => {
        if (data) {
            const _mrp = (data && data.mrp) || (data.sv_bsc && data.sv_bsc.mrp);
            if (_mrp && (_mrp !== data.offer_pr) && (_mrp !== data.offerPrice)) {
                return _mrp;
            }
        }
        return null;
    };

    const getDiscount = () => {
        if (data) {
            const _loyaltyDetails = getLoyaltyData(data);
            if (_loyaltyDetails) {
                if (data.sv_bsc) {
                    return __getPLPItemPricing(_userRoleReducer, _loyaltyDetails, data.sv_bsc).disc;
                } else {
                    return __getPLPItemPricing(_userRoleReducer, _loyaltyDetails, data).disc;
                }
            }
        }
        return null;
    };

    const getImage = () => {
        let width = 400;
        let height = 400;
        if (HK.isMobile()) {
            width = 300;
            height = 300;
        }
        let imgUrl = (data.m_img && data.m_img.o_link) || data.img || (data.pr_img && data.pr_img.o_link) || (data.pckimage && data.pckimage.o_link);
        imgUrl = convertImageCF(imgUrl, width, height);
        return imgUrl;
    };

    const getLoyaltyData = (data) => {
        if (data.sv_bsc && data.sv_bsc.hkUserLoyaltyPricingDto) {
            return data.sv_bsc.hkUserLoyaltyPricingDto;
        } else if (data.hkUserLoyaltyPricingDto) {
            return data.hkUserLoyaltyPricingDto;
        } else {
            return null;
        }
    };

    const getConcerns = (data) => {
        let concerns = data && data.concerns || [];
        if (concerns && concerns.length > 0) {
            return <div className={convertToModule(styles, "tag_flavour")} >
                {concerns.map((concern, index) => {
                    return index < 2 && <p key={"concern_" + index} className={convertToModule(styles, "product_tag")} >{`${index > 0 ? "/" : ""}`}{concern.displayName}</p>;
                })}
            </div>;
        } else return <div className={convertToModule(styles, "no-flavours")}></div>;
    };

    const getGenderStringfromGRPS = (productData) => {
        const defaultGender = "boy_girl_new.svg";
        const genderMapping = {
            "boy": "boy_new.svg",
            "men": "boy_new.svg",
            "girl": "girl_new.svg",
            "women": "girl_new.svg"
        };
        try {
            const _selAttr = productData.selAttr || (productData.sv_bsc && productData.sv_bsc.selAttr);
            const _pDataName = productData && (productData.nm || productData.dis_nm || productData.name);
            if (_selAttr) {
                const selAttribute = _selAttr['gz-gender'] && _selAttr['gz-gender'].toLowerCase();
                if (selAttribute && genderMapping[selAttribute]) {
                    return genderMapping[selAttribute];
                }
            }
            const productName = _pDataName ? _pDataName.toLowerCase() : "";
            if (productName.indexOf("boy") > -1 && productName.indexOf("girl") === -1) {
                return "boy_new.svg";
            }
            if (productName.indexOf("girl") > -1 && productName.indexOf("boy") === -1) {
                return "girl_new.svg";
            }
            return defaultGender;
        } catch (error) {
            console.log("ERROR:: ", error);
            return defaultGender;
        }


        // let gender = "";

        // for (let i = 0; i < grps.length; i++) {
        //   const grpValueModel = grps[i].values;
        //   if (grpValueModel && grpValueModel.length > 0) {
        //     for (let j = 0; j < grpValueModel.length; j++) {
        //       if ((grpValueModel[j].dis_nm || "").toLowerCase() === "gender") {
        //         gender = (grpValueModel[j].val || "").toLowerCase();
        //         break;
        //       }
        //     }
        //   }
        // }

        // if (gender === "boy" || gender === "men") {
        //   gender = "boy_new.svg";
        // } else if (gender === "girl" || gender === "women") {
        //   gender = "girl_new.svg";
        // } else if (gender === "men,women" || gender === "boy/girl") {
        //   gender = "boy_girl_new.svg";
        // } else {
        //   gender = "";
        // }
        // return gender;
    };

    const discAmount = getDiscount();
    const mrpAmount = getMRP();
    const _loyaltyData = getLoyaltyData(data);
    // let getGenderImg = ((data && data.grps && data.grps.length > 0)  || (data && data.sv_bsc && data.sv_bsc.grps && data.sv_bsc.grps.length>0))  ?  getGenderStringfromGRPS(data.grps || data.sv_bsc.grps) : '';
    let getGenderImg = getGenderStringfromGRPS(data);
    let getCombindedClass = getGenderImg.indexOf('boy_girl') > -1 ? 'boy-girl' : '';
    return (
        <div className={convertToModule(styles, `${isWidth50 ? 'width-50' : 'width-100'} col-lg-4 col-sm-10 col-xl-auto product-card ${pageType && pageType !== undefined && pageType === "home" ? "home" : ""}`)}>
            <div className={convertToModule(styles, (pageType === "menuLanding" || pageType === "search" || isWidth50) ? "product-card-wrapper no-padding-listing" : "product-card-wrapper")}>
                <div className={convertToModule(styles, `product-image-container ${pageType === 'home' ? 'home' : ''}`)}>
                    <a href={url || data.url} className={convertToModule(styles, "orange_background")}>
                        <img className={convertToModule(styles, "card-image")} src={getImage()} loading="lazy" alt={(data.m_img && data.m_img.alt) || (data.pr_img && data.pr_img.alt) || (data.pckimage && data.pckimage.alt) || "product-img"} title={(data.m_img && data.m_img.alt) || (data.pr_img && data.pr_img.alt) || (data.pckimage && data.pckimage.alt) || (data.alt) || "product-img"} />
                        {widgetType !== "ReOrder" ?
                            ((data.freebieNm && data.freebieNm.length > 0) || (data.freebie && data.freebie.length > 0)) ? <span><img style={{ display: 'block' }} id="secomapp-fg-image-6613038628996" src={HK.getImage('/Gritzo/freeGift.png')} alt="Free Gift Icon" className={convertToModule(styles, (pageType == "menuLanding" || "search") && pageType !== 'home' ? "sca-fg-img-collect width-50" : "sca-fg-img-collect")} /></span> : ''
                            : <></>}
                        {getGenderImg && getGenderImg.length > 0 ? <span><img style={{ display: 'block' }} id="secomapp-fg-image-6613038628996" src={HK.getImage(`/newGritzo/gender/${getGenderImg}`)} alt="Free Gift Icon" className={convertToModule(styles, (pageType == "menuLanding" || "search") && pageType !== 'home' ? `gender-img width-50 ${getCombindedClass}` : `gender-img ${getCombindedClass}`)} /></span> : ''}
                    </a>
                </div>

                <div className={convertToModule(styles, "product-detail")}>
                    {widgetType !== "ReOrder" ?
                        <div className={convertToModule(styles, "star-rating")}>
                            {data && data.rating > 0 && <div className={convertToModule(styles, "star")}>
                                <ReviewStarts value={data.rating} isYellow={true} />
                            </div>
                            }
                            {data.nrvw > 0 && <div className={convertToModule(styles, "rating-brd")}></div>}
                            {data.nrvw > 0 && <div className={convertToModule(styles, "left-content")}>
                                <span class="spr-badge-caption">({data.nrvw} {data.nrvw > 1 ? 'Reviews' : 'Review'})</span>
                            </div>
                            }
                        </div>
                        : <></>
                    }

                    <div className={convertToModule(styles, "product-name")} title={(data.m_img && data.m_img.alt) || (data.pr_img && data.pr_img.alt) || (data.pckimage && data.pckimage.alt) || (data.alt) || "product-img"}>
                        <a href={url} >
                            {data && (data.nm || data.dis_nm || data.name)}
                        </a>
                    </div>
                    {
                        !__isMobile() ?
                            _loyaltyData && _userRoleReducer !== HK.config.LOYALITY_ROLES.PREMIUM_USER ?
                                <ProductPriceLoyalityTag data={data} hkUserLoyaltyPricingDto={_loyaltyData} />
                                : <div className={styles['loyalty-not-avail']}></div>
                            : <></>
                    }
                    {
                        (getAge() || getFlavour()) ?
                            <div className={convertToModule(styles, "tag_flavour")} >
                                <p className={convertToModule(styles, "product_tag")} >{getAge()} |</p>
                                <p className={convertToModule(styles, "product_tag")} >&nbsp; {getFlavour()}</p>
                            </div>
                            :
                            <div className={convertToModule(styles, "no-flavours")}></div>
                    }
                    {
                        placeholderConfig && placeholderConfig.isConcern && data ?
                            getConcerns(data)
                            : <></>
                    }
                    <div className={convertToModule(styles, "form-container")}>
                        <div className={convertToModule(styles, `product-card-footer`)} >
                            <div className={convertToModule(styles, "price-container")} >
                                {placeholderConfig && placeholderConfig.isDiscount && <div className={convertToModule(styles, "product_price_details")} >
                                    {
                                        mrpAmount ?
                                            <span className={convertToModule(styles, "product-sale-price")} data-compare-price="">
                                                ₹{mrpAmount}
                                            </span>
                                            : <></>
                                    }
                                    {
                                        discAmount ?
                                            <span className={convertToModule(styles, "product-offer")} >
                                                ({discAmount}% Off)
                                            </span>
                                            : <></>
                                    }
                                </div>}
                                <div className={convertToModule(styles, "price_select")}>
                                    {
                                        data &&
                                        <p className={convertToModule(styles, "product-price")}>₹{__getPLPItemPricing(_userRoleReducer, _loyaltyData, data).price || data.offerPrice || data.offer_pr}</p>
                                    }
                                    {
                                        data && data.selAttr && data.selAttr["fn-att-quant"] &&
                                        <>
                                            <span className={convertToModule(styles, "slash")}>/</span>
                                            <div className={convertToModule(styles, "select-prod-container")}>
                                                <select className={convertToModule(styles, "select-option")} name="id" id="">
                                                    <option selected="selected">{data && data.selAttr && data.selAttr["fn-att-quant"]}</option>
                                                </select>

                                            </div>
                                        </>
                                    }

                                </div>
                            </div>
                            {
                                __isMobile() ?
                                    _loyaltyData && _userRoleReducer !== HK.config.LOYALITY_ROLES.PREMIUM_USER ?
                                        <ProductPriceLoyalityTag data={data} hkUserLoyaltyPricingDto={_loyaltyData} />
                                        : <></>
                                    : <></>
                            }
                            {
                                <div className={convertToModule(styles, `ctas`)}>
                                    
                                    {
                                        __loggedIn() ?
                                            <BuyNow
                                                id={data && (data.id || (data.sv_bsc && data.sv_bsc.sv_id) || (data.value))}
                                                isPack={isPack || (data && data.pk_type) || (data && data.url && data.url.indexOf('/pk') > -1 ? true : false)}
                                                vendorID={data && (data.vendorId || (data.sv_bsc && data.sv_bsc.vendorId))}
                                                varData={{
                                                    id: (data && (data.id || (data.sv_bsc && data.sv_bsc.sv_id) || (data.value))) || "",
                                                    name: data && data.nm || "",
                                                    category: data && data.catName || "",
                                                    offerPrice: data && data.offer_pr || 0,
                                                    brName: data && data.brName || "",
                                                    img: data && data.pr_img ? data && data.pr_img.o_link : "",
                                                    widgetName: title || "",
                                                    eventName: eventName,
                                                    widgetPosition: widgetPosition + 2,
                                                    itemPosition: index + 1
                                                }}
                                                widgetName={title}
                                                itemPosition={index + 1}
                                            />
                                            :
                                            <AddToCart
                                                id={data && (data.id || (data.sv_bsc && data.sv_bsc.sv_id) || (data.value))}
                                                isPack={isPack || (data && data.pk_type) || (data && data.url && data.url.indexOf('/pk') > -1 ? true : false)}
                                                vendorID={data && (data.vendorId || (data.sv_bsc && data.sv_bsc.vendorId))}
                                                event={"Is_MB_Home_Add-Buy"}
                                                eventCat={"Home Page"}
                                                eventAct={"Add to Cart"}
                                                varData={{
                                                    id: (data && (data.id || (data.sv_bsc && data.sv_bsc.sv_id) || (data.value))) || "",
                                                    name: data && data.nm || "",
                                                    category: data && data.catName || "",
                                                    offerPrice: data && data.offer_pr || 0,
                                                    brName: data && data.brName || "",
                                                    img: data && data.pr_img ? data && data.pr_img.o_link : "",
                                                    widgetName: title || "",
                                                    eventName: eventName,
                                                    widgetPosition: widgetPosition + 2,
                                                    itemPosition: index + 1
                                                }}
                                                buttonDisplayName={"Buy Now"}
                                                redirectToCart={true}
                                                widgetName={title}
                                                itemPosition={index + 1}
                                                atcAs={'BuyNow'}
                                            />
                                    }
                                    <AddToCart
                                        wrapperClass={convertToModule(styles, "atc-custom")}
                                        id={data && (data.id || (data.sv_bsc && data.sv_bsc.sv_id) || (data.value))}
                                        isPack={isPack || (data && data.pk_type) || (data && data.url && data.url.indexOf('/pk') > -1 ? true : false)}
                                        vendorID={data && (data.vendorId || (data.sv_bsc && data.sv_bsc.vendorId))}
                                        event={"Is_MB_Home_Add-Buy"}
                                        eventCat={"Home Page"}
                                        eventAct={"Add to Cart"}
                                        varData={{
                                            id: (data && (data.id || (data.sv_bsc && data.sv_bsc.sv_id) || (data.value))) || "",
                                            name: data && data.nm || "",
                                            category: data && data.catName || "",
                                            offerPrice: data && data.offer_pr || 0,
                                            brName: data && data.brName || "",
                                            img: data && data.pr_img ? data && data.pr_img.o_link : "",
                                            widgetName: title || "",
                                            eventName: eventName,
                                            widgetPosition: widgetPosition + 2,
                                            itemPosition: index + 1
                                        }}
                                        widgetName={title}
                                        itemPosition={index + 1}
                                    />
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        _userRoleReducer: state.userRoleReducer,
    };
}
export default connect(mapStateToProps)(GritzoProductCard);
